#workingOn-section {
  display: flex;
  padding: 128px 221px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  background: #FBFBFB;
}

#working-on-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

#project-block {
  gap: 80px;
}

#block-project {
  display: flex;
  width: 461px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

#block-supporting {
  display: flex;
  width: 461px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-self: stretch;
}

.main-title {
  margin: 0;
  color: #18181A;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.4px;
}

#workingOn-pic {
  width: 263px;
  height: 315px;
}

#supportFund-pic {
  width: 233px;
  height: 233px;
}

.title {
  color: var(--neutral-neutral-01, #18181A);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.4px;
}

.support-fund-title {
  padding-top: 30px;
  color: var(--neutral-neutral-01, #18181A);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.4px;
}

.working-text {
  color: var(--neutral-neutral-01, #18181A);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
}

.link {
  color: var(--Secondary-color, #0F54DB);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  #working-on-container {
    width: max-content;
  }
}

@media screen and (max-width: 768px) {
  #working-on-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space_1, 8px);
    align-self: stretch;
  }

  .main-title {
    width: max-content;
    color: #18181A;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.4px;
  }

  #project-block {
    gap: 80px;
  }
}

@media screen and (min-width: 1200px) {
  #inner {
    display: flex;
    height: 267px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .link {
    margin-top: auto;
  }
}