.nav {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.80);
}

.brand-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo-text {
  width: 80px;
  height: 48px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: #18181a;
  text-align: start;
}

.inner-text {
  height: 22px;
  width: auto;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #18181a !important;
}

#responsive-navbar-nav-control {
  position: absolute;
  top: 5em;
  right: 1%;
  margin-top: -45px;
  width: 3em;
  height: 45px;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

#responsive-navbar-nav {
  justify-content: flex-end;
}

.offcanvas {
  width: 100% !important;
  max-width: 100% !important;
}

.offcanvas-header {
  width: 100%;
  padding: 1rem;
}

.offcanvas-logo {
  width: 100%;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.offcanvas .nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offcanvas .nav-link .nav-dropdown {
  padding: 2rem 50rem;
  text-align: center;
  border-bottom: 0.8px solid grey;
}

.offcanvas .nav-link+.nav-link .nav-dropdown {
  margin-top: 1.5rem;
}

#navbar-container {
  display: flex;
  width: 1008px;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.brand-container {
  display: flex;
  flex-direction: row;
  width: 130px;
  height: 58.48px;
}

.link-container {
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  width: 100% 411px;
  height: auto 57px;
}

.logo {
  width: 50.119px;
  height: 50.333px;

}

.logo-text {
  width: 80px;
  height: 48px;
  color: var(--neutral-neutral-01, #18181A);
  font-size: 14.118px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

#responsive-navbar-nav-control {
  position: absolute;
  right: 1%;
  margin-top: -45px;
  width: 3em;
  height: 45px;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  border: none;
  top: 4rem;

}

#responsive-navbar-toggle {
  justify-content: flex-end;
}

.offcanvas {
  width: 100% !important;
  max-width: 100% !important;
}

.offcanvas-header {
  width: 100%;
  padding: 1rem;
}


.offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.offcanvas .nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offcanvas .nav-link+.nav-link {
  margin-top: 2rem;
}

.offcanvas .nav-link+.nav-dropdown {
  margin-top: 2rem;
}

.nav-link {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #18181a;
}

.nav-dropdown .inner-text+.inner-text {
  margin-top: 10px;
}

.nav-dropdown .dropdown-item {
  background-color: transparent;
}

.nav-dropdown .dropdown-menu {
  border: none;
  margin-top: 1em;
  background-color: transparent;
}

.fixed-nav {
  width: 35%;
  height: 35%;
}

.title-spacing {
  word-spacing: 0.2em;
}