#projectTracker-container {
    padding-top: 128px;
    max-width: 1440px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 64px;
}

.project-h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

#ProjectTracker-title-2 {
    color: #18181A;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.4px;
}

#topSection-title {
    color: #000;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: 0.4px;
    background: linear-gradient(to top, #ffd500 50%, #ffd500 50%, transparent 50%, transparent 100%);
    white-space: nowrap;
}

#eye {
    width: 100px;
    height: var(--Section-Vertical-Margin-Large, 100px);
    flex-shrink: 0;
}

.p2 {
    font-size: 16px;
}

.pj-titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 64px;
}

#working-text {
    text-align: left;
    margin-top: 24px;
}


#mac {
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    width: 100%;
    height: auto;
}

#tracker-button {
    background-color: rgb(255, 197, 48);
    border-color: rgb(255, 197, 48);
    border-radius: 2px;
    width: 300px;
    height: 61px;
    line-height: 61px;
    font-size: 20px;
    color: rgb(55, 42, 6);
    text-align: center;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 20px;
    border-radius: var(--Redius, 2px);
    border: 1px solid var(--Primary-color, #ffc530) !important;
    background: var(--Primary-color, #ffc530) !important;
    padding: 0px !important;
}

#tracker-button:hover {
    color: white;
}

#button-container {
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    text-transform: uppercase;
    padding: 0px !important;
}

#picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#what-is-tracker {
    padding-bottom: 128px;

}

/* Phone */
@media screen and (max-width: 767px) {
    #eye {
        margin-top: 120px;
    }

    #topSection-title {
        font-size: 32px;
        line-height: 40px;
        white-space: wrap;
        margin-top: 20px;
        display: inline-block;
    }

    #ProjectTracker-title-2 {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.4px;
    }

    #picture-container {
        height: 215px;
        width: 366.48px;
        transform: translateX(-20px);
    }

    .pj-titleContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 32px;
    }

    #projectTracker-container {
        padding: 0 24px;
    }

    #button-container {
        justify-content: center;
        letter-spacing: 0px;
        line-height: auto;
        transform: translateY(40px)
    }

    .p2 {
        font-size: 12px;
        text-align: left;
        margin-top: 20px;
    }

    .project-h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    #working-text {
        font-size: 12px;
    }
}