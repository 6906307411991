.container {
  max-width: 1440px;
  padding: 0;
}

#title-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  padding: 128px 120px 64px 120px;
  max-width: 1440px;
}

#contact-title {
  padding: 0;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 3vw;
  letter-spacing: 0.4px;
  background: linear-gradient(to top,
      #ffd500 50%,
      #ffd500 60%,
      transparent 50%,
      transparent 100%);
}

.subtitlep {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#how-we-can-help-container {
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

#help-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

#imageSection {
  display: flex;
  flex-direction: column;
  max-width: 1008px;
  height: 374px;
  align-items: center;
  gap: 12px;
  padding: 20px;
  justify-content: center;
}

.imgSec {
  display: flex;
  width: 519px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
}

.img-text {
  color: #18181A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.helpbottom {
  background: #FBFBFB;
  padding-bottom: 128px;
  padding-top: 128px;
}

@media (max-width: 765px) {
  #imageSection {
    flex-direction: row;
  }

  #how-we-can-help-container {
    padding: 0;
    margin-bottom: 100px;
  }

  #contact-title {
    line-height: 5vw;
  }
}