/* Largest screen width is 1440px, all default settings are the css style in 1440px. */

#about-us-container {
  position: relative;
  max-width: 1440px;
  height: 55.5rem;
  padding: 8rem 13.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heart {
  margin-top: -220px;
  max-width: 1440px;
  width: 100%;
}

.about-us-row {
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
}

.about-us-title {
  font-size: 44px;
  font-weight: bold;
  font: normal;
  color: #18181A;
  text-align: left;
  padding: 0;
  width: 21%;
}

.subtitle {
  color: #18181A;
  font-weight: bold;
  font-size: 22px;
  text-align: left;
  padding: 0;
  width: 13%;
}

.about-paragraph {
  font-size: 16px;
  text-align: left;
  font-family: sans-serif;
  padding: 0;
  width: 59.5%;
}

.signupTitle {
  color: #333;
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 35px;
  text-transform: capitalize;
}

@media screen and (min-width: 1105px) and (max-width: 1439px) {
  .about-us-title {
    width: 30%;
  }

  .subtitle {
    width: 30%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1104px) {
  #about-us-container {
    padding: 10% 15%;
  }

  .about-us-title {
    width: 30%;
  }

  .subtitle {
    width: 30%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
  #about-us-container {
    padding: 10% 10%;
  }

  .about-us-title {
    width: 35%;
  }

  .subtitle {
    width: 30%;
  }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
  .heart {
    height: auto;
    width: 100%;
  }

  #about-us-container {
    margin: 4rem 0 0 0;
    padding: 0 7%;
    height: auto;
  }

  .about-us-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  .about-us-title {
    width: 100%;
  }

  .subtitle {
    width: 100%;
  }

  .about-paragraph {
    margin-top: 1rem;
    width: 100%;
  }
}