#supportFund-section {
    display: flex;
    padding: 128px 221px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: #FBFBFB;
}

#self-reporting-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
}

#support-fund-content-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.main-title {
    font-size: 32px;
    font-weight: 600;
}

.working-text {
    color: var(--neutral-neutral-01, #18181A);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    width: 1008px;
    justify-content: center;
}

#project-block {
    gap: 80px;
}

#block-project {
    display: flex;
    width: 461px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

#iphones {
    display: flex;
    width: 100%;
    height: auto;
    max-width: 500px;
}

#bottom-section {
    padding-top: 256px;
    padding-left: 120px;
    padding-right: 120px;
}

#support-fund-title {
    padding-bottom: 28px;
}

#hero-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}






