.drop-down {
  background-color: grey;
  position: absolute;
  width: 100%;
  height: 85%;
  z-index: 2;
  opacity: 0.5;
}

.add-data-button {
  color: white;
  margin-top: 12rem;
  margin-left: 25rem;
  z-index: 3;
  position: absolute;
}

.report-button {
  color: yellow !important;
  text-decoration: underline !important;
}

.report-button:hover {
  text-shadow: 2px #ffffff;
}

.recharts-wrapper {
  width: 80%;
  height: 300px;
  border-radius: 16px;
}

.recharts-custom-tooltip {
  background-color: #f0f1ff;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 1px;
}

.date {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  color: rgba(8, 13, 31, 0.7);
}

.cases {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  color: #080d1f;
}

@media (max-width: 811px) {
  .add-data-button {
    margin-top: 40%;
    margin-left: 5%;
    z-index: 3;
    position: absolute;
  }
}

@media (min-width: 812px) {
  .add-data-button {
    margin-top: 13%;
    margin-left: 15%;
    z-index: 3;
    position: absolute;
  }
}