#story {
  width: 100%;
  border: none;
  --bs-card-bg: transparent;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  margin-bottom: 40px;
}

#story-link {
  margin-top: auto;
  align-self: center;
  color: var(--Secondary-color, #0F54DB);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#our-sotry-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.our-story {
  margin: 0;
  color: #18181A;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.4px;
}

#story-section {
  display: flex;
  padding: 128px 32px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

#profile-pic {
  width: 292px;
  height: 292px;
  border-radius: 600px;
}

.story-text {
  text-align: left;
  color: var(--neutral-neutral-01, #18181A);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

#story-name {
  color: var(--neutral-neutral-01, #18181A);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.4px;
}

#story-quote {
  color: var(--neutral-neutral-01, #18181A);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.story-button {
  display: flex;
  width: 201px;
  height: 61px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--Redius, 2px);
  border: 1px solid var(--Primary-color, #FFC530);
  background: var(--Primary-color, #FFC530);
  color: #372A06;
  text-align: center;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

/* Responsive */
@media screen and (min-width: 985px) {
  .our-story {
    margin: 0;
    font-style: normal;
    font-weight: 800;
    color: #18181A;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.4px;
  }

  #story-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 10% 18%;
    max-width: 1440px;
  }

  #stories {
    gap: 40px;
    border: none;
    --bs-card-bg: transparent;
    display: flex;
    align-items: center;
    align-self: stretch;
    max-width: 1440px;
  }

  #profile-pic {
    width: 292px;
    height: 292px;
    border-radius: 600px;
  }

  .story-text {
    color: var(--neutral-neutral-01, #18181A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  #story-name {
    color: var(--neutral-neutral-01, #18181A);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 116.667% */
    letter-spacing: 0.4px;
  }

  #story-quote {
    color: var(--neutral-neutral-01, #18181A);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  .story-button {
    display: flex;
    width: 201px;
    height: 61px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Redius, 2px);
    border: 1px solid var(--Primary-color, #FFC530);
    background: var(--Primary-color, #FFC530);
    color: #372A06;
    text-align: center;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1441px) {
  #stories {
    gap: 40px;
    border: none;
    --bs-card-bg: transparent;
    display: flex;
    align-items: center;
    align-self: stretch;
    max-width: 1440px;
  }
}

@media screen and (max-width: 768px) {
  #our-sotry-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }

  .our-story {
    margin: 0;
    color: #18181A;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.4px;
  }

  #our-sotry-text-container>img {
    width: 37.397px;
    height: 23.5px;
  }

  .story-text {
    text-align: left;
    color: var(--neutral-neutral-01, #18181A);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
  }

  #story-section {
    display: flex;
    padding: 128px 32px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
  }

  #story-quote {
    color: var(--neutral-neutral-01, #18181A);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  #story-name {
    color: var(--neutral-neutral-01, #18181A);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0.4px;
  }
}