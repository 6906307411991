#SupportFund-container {
  padding-top: 100px;
  max-width: 1440px;
}

#support-titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  padding-bottom: 64px;
}

#supportHeading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

#support-sideLogo {
  width: 37.397px;
  height: 23.5px;
}

#supportHeadingText {
  color: #18181A;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.4px;
}

.support-section-content {
  display: flex;
  width: 1008px;
  height: 374px;
  align-items: center;
  gap: 24px;
}

#support-section2-text-container {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.s1 {
  text-align: left;
  color: #18181A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.s2 {
  color: var(--Neutral-Neutral_01, #18181A);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

#p1 {
  padding: 24px 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background: #FFF;
}

#symbol {
  width: 5px;
  align-self: stretch;
  background: var(--Secondary-color, #0F54DB);
}

#Donate-button {
  display: flex;
  width: 201px;
  height: 61px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--Redius, 2px);
  border: 1px solid var(--Primary-color, #FFC530);
  background: var(--Primary-color, #FFC530);
  padding: 0px;
}

#Donate-button:hover .chart-button-text {
  color: white;
}

#support-paragrah {
  color: #18181A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

#button-container {
  padding-left: 100px;
}

@media screen and (min-width:414px) and (max-width:768px) {
  #support-section {
    gap: 50px;
  }

  #main-support-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 10%;
  }

  #support-section2-text-container {
    padding-bottom: 10%;
  }

  #topSection-title {
    font-size: 33px;
  }

  #supportHeading-container {
    flex-direction: column;
  }

  #supportHeadingText {
    width: 355px;
  }

  .money-gose-content {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width:769px) {
  #support-sections {
    padding: 128px 120px;
    display: flex;
    max-width: 1440px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 120px;
    background: #FBFBFB;
  }

  .contentRow {
    display: flex;
    width: 100%;
    height: 374px;
    align-items: center;
    gap: 24px;
  }

  .support-contents {
    max-width: 453px;
    height: 198px;
    color: #18181A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    align-self: stretch;
  }

  #main-support-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    align-self: stretch;
  }

  #support-number {
    display: flex;
    flex-direction: row;
  }
}