#hero-card {
  max-width: 1440px;
  margin: 0 auto;
  background: #fbfbfb;
  border: hidden;
}

#hero-image {
  max-width: 1440px;
}

.hero-wrapper {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

#highlight-background {
  background-color: yellow;
  padding: 10px;
}

.h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin: 0;
}

#middle {
  display: flex;
  justify-content: center;
}

#hero-container {
  padding-top: 130px;
  background: #fbfbfb;
  max-width: 1440px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.hero-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(255, 197, 48);
  border-color: rgb(255, 197, 48);
  border-radius: 2px;
  width: 201px;
  height: 61px;
  font-size: 20px;
  color: rgb(55, 42, 6);
  text-align: center;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0px !important;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #hero-text-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    width: 30%;
    max-width: 432px;
    height: 90%;
    left: 10%;
    top: 5%;
    background-color: yellow;
    box-sizing: border-box;
    background: #ffd500;
    border: 1px solid #000000;
  }

  #hero-title {
    color: #18181a;
    background: linear-gradient(to top,
        #ffd500 50%,
        #ffd500 50%,
        transparent 50%,
        transparent 100%);
    text-align: center;
    font-family: sans-serif;
    font-size: 7vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.549px;
  }

  .p2 {
    color: var(--neutral-neutral-01, #18181a);
    text-align: center;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }

  #hero-subtitle {
    text-align: start;
    margin-left: 1.8vw;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 32px;
  }

  .text-section {
    align-items: center !important;
    gap: 4px;
    margin-top: 2.5vh;
  }

  .title-2 {
    color: var(--neutral-neutral-01, #18181a);
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.4px;
  }
}

@media screen and (min-width: 768px) {
  #hero-container .text-section {
    padding-left: 40px;
  }

  #hero-title {
    display: inline-block;
    color: #18181a;
    background: linear-gradient(to top,
        #ffd500 50%,
        #ffd500 50%,
        transparent 50%,
        transparent 100%);
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 107.986%;
  }

  .p2 {
    color: var(--neutral-neutral-01, #18181a);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }

  #hero-subtitle {
    display: flex;
    text-align: start;

  }

  .text-section {
    width: 400px;
    height: 522px;
    gap: 20px;
  }

  .title-2 {
    color: var(--neutral-neutral-01, #18181a);
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.4px;
  }
}

@media screen and (max-width: 1160px) {
  #middle {
    justify-content: center;
  }
}

@media screen and (min-width: 1440px) {
  .hero-text-container {
    display: flex;
    width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    align-self: stretch;
  }

  .title-container {
    display: flex;
    width: 1440px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .p2 {
    color: var(--neutral-neutral-01, #18181a);
    font-family: sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-left: 0px;
  }

  .hero-image-container {
    margin-left: 200px;
    display: flex;
    width: 1008px;
    justify-content: space-between;
    align-items: center;
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
  }

  .title-2 {
    color: #18181a;
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
  }

  #hero-title {
    font-size: 75px;
  }
}